import {
  addLineItemsHandler,
  clearCartHandler,
  getCartHandler,
  updateCartHandler,
  updateLineItemHandler,
  applyDiscountCodeHandler,
} from '@/cart/cart-api';
import {
  Cart,
  CartLineItem,
  LineItemInput,
  UpdateCartInput,
  UpdateLineItemInput,
} from '@/cart/types';
import {logError, logInfo} from '@/utilities/log';
import {TaskQueue} from '@/utilities/task-queue';
import {withDeduplication} from '@/utilities/with-deduplication';
import {signal} from '@preact/signals';
import {optimisticAddLineItems} from './optimistic-updates/optimistic-add-line-items';
import {optimisticUpdateLineItem} from './optimistic-updates/optimistic-update-line-item';
import {discountCodesUpdateHandler} from '@/cart/cart-storefront-api';
import {client} from '@/shopify-storefront/client';

const cartRootRoute = signal('/');
const cart = signal<Cart | null>(null);

const callbacks = {
  mapLineItems: (item: LineItemInput) => item,
  onAddLineItems: [] as OnAddLineItemsCallback[],
  onCartError: [] as OnCartErrorCallback[],
  onClearCart: [] as OnClearCartCallback[],
  onBeforeCheckout: [] as OnBeforeCheckoutCallback[],
  onUpdateCart: [] as OnUpdateCartCallback[],
  onUpdateLineItem: [] as OnUpdateLineItemCallback[],
};

/*
 * Cart updates are executed in a queue to ensure they are processed in order.
 * This prevents concurrent updates to the Shopify Cart API, which can lead to unexpected results.
 */
const queue = new TaskQueue(async () => {
  try {
    const newCartState = await withDeduplication('getCart', () =>
      getCartHandler()
    );

    // Check that the queue is still empty after retrieving the cart
    // This helps reduce jank in the UI
    if (queue.isEmpty) {
      cart.value = newCartState;
      logInfo('UpdatedCartState', {cart: cart.value});
    }
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingCart'});
    handleError(error);
  }
});

/**
 * Used to identify the customer's selected donation category for the Buy 1 Give 10 program
 * This is a custom attribute that is added to the cart items.
 * It should be prefixed with an underscore to make it private, so it won't be displayed in Shopify checkout.
 *
 * @see {@link https://shopify.dev/docs/api/ajax/reference/cart#private-properties-and-attributes}
 */
const donationAttribute = '_buy_one_give_ten_category';

/**
 * Add line items to the cart.
 * If successful, returns the updated cart and the added line items.
 * If unsuccessful, returns null.
 * The cart state will be updated and callbacks registered with `onAddLineItems` will be called.
 */
export async function addLineItems(
  items: LineItemInput[]
): Promise<[Cart | null, CartLineItem[]] | null> {
  try {
    const itemsMapped = items.map(callbacks.mapLineItems);

    // Optimistically update the cart state
    if (cart.value) {
      cart.value = optimisticAddLineItems(itemsMapped, cart.value);
    }

    // Push 'add line itemsMapped' task to the queue
    const addedItems = await queue.enqueueAsync(() =>
      addLineItemsHandler(itemsMapped, cartRootRoute.value)
    );

    // Call any callbacks registered with `onAddLineItems`
    await Promise.allSettled(
      callbacks.onAddLineItems.map((cb) =>
        cb(cart.value, addedItems, itemsMapped)
      )
    );
    logInfo('AddedItemsToCart', {items, itemsMapped, addedItems});

    return [cart.value, addedItems];
  } catch (error) {
    logError(error, {message: 'ErrorAddingItemsToCart', items});
    return null;
  }
}

/**
 * Navigate to the checkout page.
 * Callbacks registered with `onBeforeCheckout` will be called.
 */
export async function checkout(url = '/checkout'): Promise<void> {
  try {
    await Promise.all(callbacks.onBeforeCheckout.map((cb) => cb(cart.value)));
    // Wait for any pending tasks to complete before navigating to the checkout page
    await queue.enqueueAsync(() => Promise.resolve());
  } catch (error) {
    handleError(error);
  }
  logInfo('NavigateToCheckout', {url});
  window.location.href = url;
}

/**
 * Clears the cart.
 * If successful, returns the cleared cart.
 * If unsuccessful, returns null.
 * The cart state will be updated and callbacks registered with `onClearCart` will be called.
 */
export async function clearCart(): Promise<Cart | null> {
  try {
    if (cart.value) {
      cart.value = {...cart.value, items: []};
    }
    await queue.enqueueAsync(() => clearCartHandler(cartRootRoute.value));
    await Promise.all(callbacks.onClearCart.map((cb) => cb(cart.value))).catch(
      handleError
    );
    logInfo('ClearedCart');
    return cart.value;
  } catch (error) {
    handleError(error);
    return null;
  }
}

/**
 * Returns the current cart, or `null` if it is not loaded yet.
 * If the cart has not been loaded, it will be loaded.
 */
export function getCart(): Cart | null {
  if (!cart.value) {
    withDeduplication('loadCart', loadCart).catch(handleError);
  }
  return cart.value;
}

/**
 * Retrieves the donation category from the cart items.
 * If the donation category has not been set, returns `null`.
 *
 * @deprecated Earth Breeze will no longer offer the option to select donation category. All logic related to donation categories should be deprecated from the site.
 * @returns The donation category as a string, or null if not found.
 */
export function getDonationCategory(
  attributeName = donationAttribute
): string | null {
  const items = cart.value?.items ?? [];
  for (const item of items) {
    const donationCategory = item.properties?.[attributeName];
    if (donationCategory) {
      return donationCategory;
    }
  }
  return null;
}

/**
 * Gets the cart from the API and sets the cart state.
 */
export async function loadCart(): Promise<Cart | null> {
  try {
    cart.value = await getCartHandler(cartRootRoute.value);
    logInfo('LoadedCart', {cart: cart.value});
    return cart.value;
  } catch (error) {
    handleError(error);
    return null;
  }
}

/**
 * Updates the cart.
 * If successful, returns the updated cart.
 * If unsuccessful, returns null.
 * The cart state will be updated and callbacks registered with `onUpdateCart` will be called.
 */
export async function updateCart(input: UpdateCartInput): Promise<Cart | null> {
  try {
    await queue.enqueueAsync(() =>
      updateCartHandler(input, cartRootRoute.value)
    );
    await Promise.all(
      callbacks.onUpdateCart.map((cb) => cb(cart.value, input))
    ).catch(handleError);
    logInfo('UpdatedCart', {input, cart: cart.value});
    return cart.value;
  } catch (error) {
    handleError(error);
    return null;
  }
}

/**
 * Updates a line item in the cart.
 * If successful, returns the updated cart.
 * If unsuccessful, returns null.
 * The cart state will be updated and callbacks registered with `onUpdateLineItem` will be called.
 *
 * @param item - The updated line item information.
 * @returns A Promise that resolves to the updated Cart object, or null if an error occurs.
 */
export async function updateLineItem(
  item: UpdateLineItemInput
): Promise<Cart | null> {
  try {
    if (cart.value) {
      cart.value = optimisticUpdateLineItem(item, cart.value);
    }
    await queue.enqueueAsync(() =>
      updateLineItemHandler(item, cartRootRoute.value)
    );

    await Promise.all(
      callbacks.onUpdateLineItem.map((cb) => cb(cart.value, item))
    ).catch(handleError);

    logInfo('UpdatedLineItem', {item, cart: cart.value});

    return cart.value;
  } catch (error) {
    handleError(error);
    return null;
  }
}

/**
 * Apply multiple discount codes to the cart.
 * Uses Shopify Storefront API to apply the discount codes.
 * This overwrites all other discounts on the cart.
 */
export async function applyDiscountCodes(codes: string[]): Promise<void> {
  try {
    await queue.enqueueAsync(async () => {
      const {token} = await getCartHandler(cartRootRoute.value);

      if (!token) return;

      const cartId = `gid://shopify/Cart/${token}`;
      await discountCodesUpdateHandler(client, cartId, codes);
    });
  } catch (error) {
    handleError(error);
  }
}

/** Apply a discount code to the cart.
 * This uses the discount share URL to the discount code.
 * It overwrites all other discounts on the cart.
 */
export async function applyDiscountCode(code: string): Promise<void> {
  try {
    await queue.enqueueAsync(async () => {
      await applyDiscountCodeHandler(code, cartRootRoute.value);
      await loadCart();
    });
  } catch (error) {
    handleError(error);
  }
}

/**
 * Registers a callback to be called when line items are added to the cart.
 */
export function onAddLineItems(cb: OnAddLineItemsCallback): void {
  callbacks.onAddLineItems.push(cb);
}

/**
 * Registers a callback to be called before the checkout page is loaded.
 */
export function onBeforeCheckout(cb: OnBeforeCheckoutCallback): void {
  callbacks.onBeforeCheckout.push(cb);
}

/**
 * Registers a callback to be called when an error occurs.
 */
export function onCartError(cb: OnCartErrorCallback): void {
  callbacks.onCartError.push(cb);
}

/**
 * Registers a callback to be called when the cart is cleared.
 */
export function onClearCart(cb: OnClearCartCallback): void {
  callbacks.onClearCart.push(cb);
}

/**
 * Registers a callback to be called when the cart is updated.
 */
export function onUpdateCart(cb: OnUpdateCartCallback): void {
  callbacks.onUpdateCart.push(cb);
}

/**
 * Registers a callback to be called when a line item is updated.
 */
export function onUpdateLineItem(cb: OnUpdateLineItemCallback): void {
  callbacks.onUpdateLineItem.push(cb);
}

export function setMapLineItems(
  cb: (item: LineItemInput) => LineItemInput
): void {
  callbacks.mapLineItems = cb;
}

/**
 * Sets the root route for the cart API.
 * If the route has changed, the cart will be reloaded.
 * This ensures the cart data returned is in the correct language and locale.
 */
export async function setRootRoute(route: string) {
  const newRoute = route.endsWith('/') ? route : `${route}/`;
  if (newRoute === cartRootRoute.value) {
    return;
  }
  cartRootRoute.value = newRoute;
  await loadCart();
}

/**
 * Handles an error by calling callbacks registered with `onCartError`.
 */
function handleError(error: unknown): void {
  callbacks.onCartError.forEach((cb) => cb(cart.value, error) as void);
}

type BaseCallback<T extends unknown[]> =
  | ((cart: Cart | null, ...args: T) => void)
  | ((cart: Cart | null, ...args: T) => Promise<void>);

type OnBeforeCheckoutCallback = BaseCallback<[]>;
type OnAddLineItemsCallback = BaseCallback<[CartLineItem[], LineItemInput[]]>;
type OnUpdateLineItemCallback = BaseCallback<[UpdateLineItemInput]>;
type OnClearCartCallback = BaseCallback<[]>;
type OnUpdateCartCallback = BaseCallback<[UpdateCartInput]>;
type OnCartErrorCallback = BaseCallback<[unknown]>;
